<template>
  <div class="flex items-center justify-center">
    <div v-if="waiting" class="card p-4">
      <arrow-path-icon class="h-10 w-10 animate-spin text-slate-500" />
    </div>
    <div v-else class="card p-4">
      <h1>{{ t("headings.order_received") }}</h1>
      <p class="py-4">{{ t("text.order_received") }}</p>
      <hr class="my-4 border-slate-300" />
      <div class="flex justify-end">
        <form-button
          :command="() => router.push('/orders')"
          :disabled="waiting"
          id="orders-button"
          :label="'to_orders'"
          :textVariant="company.primaryText"
          type="button"
          :variant="company.primary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowPathIcon } from "@heroicons/vue/24/outline";
import { computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import FormButton from "../components/form/FormButton.vue";
export default {
  components: { ArrowPathIcon, FormButton },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.state.company.company);

    const handleTransactionData = () => {
      const data = Object.fromEntries(new URLSearchParams(location.search));
      store.dispatch("order/updateOrder", data);
    };

    onMounted(() => handleTransactionData());

    return {
      company,
      router,
      t,
      waiting: computed(() => store.state.order.waiting),
    };
  },
};
</script>
